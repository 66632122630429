import 'styled-components';

const theme = {
  colors: {
    aquaGreen: '#33BDA9',
    aquaGreen60: 'rgba(51, 189, 169, 0.6)',
    aquaGreen20: 'rgba(51, 189, 169, 0.2)',
    babyBlue: '#4797FF',
    babyBlue60: 'rgba(71, 151, 255, 0.6)',
    babyBlue20: 'rgba(71, 151, 255, 0.2)',
    black: '#000000',
    hcsBlue: '#E2EEFF',
    nightBlue: '#06135A',
    nightBlue60: 'rgba(6, 19, 90, 0.6)',
    nightBlue40: 'rgba(6, 19, 90, 0.4)',
    nightBlue20: 'rgba(6, 19, 90, 0.2)',
    pomeloRed: '#FF2C58',
    pomeloRed60: 'rgba(255, 44, 88, 0.6)',
    white: '#FFFFFF',
    white60: 'rgba(255, 255, 255, 0.6)',
  },
  fonts: {
    montserrat: 'Montserrat, Helvetica, sans-serif',
    lato: 'Lato, Helvetica, sans-serif',
    permanentMarker: 'Permanent Marker, Montserrat, Helvetica, sans-serif',
    patrickHand: 'Patrick Hand, Permanent Marker, Lato, Helvetica, sans-serif',
  },
  border: {
    default: '1px solid rgba(6, 19, 90, 0.2)',
  },
} as const;

export default theme;
