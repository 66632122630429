import { createGlobalStyle } from 'styled-components';

import theme from 'styles/theme';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100%;
    font-family: ${theme.fonts.lato};
    color: ${theme.colors.nightBlue};
    background-color: ${theme.colors.hcsBlue};

    & > div {
      height: 100%;
    }
  }

  a {
    text-decoration: none;
    color: ${theme.colors.babyBlue};
    transition: color 0.15s ease;

    @media (hover: hover) {
      &:hover {
        color: ${theme.colors.babyBlue60};
      }
    }
  }

  .Toastify__toast-container--bottom-center {
    bottom: 122px;
  }
`;

export default GlobalStyle;
